/* -------------------------------------------------------------------------------
    Variables
   ------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------
    HTML Elements
   ------------------------------------------------------------------------------- */
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
  min-height: 100.1%;
  padding-top: 117px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
blockquote,
th {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  font-weight: 700;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h6 {
  text-transform: uppercase;
  font-weight: 400;
}
:focus,
:visited,
:hover,
:active,
a:focus,
a:visited,
a:hover,
a:active,
button:focus {
  outline: 0;
  text-decoration: none;
  box-shadow: none !important;
}
a {
  color: #646c61;
  text-decoration: underline;
}
a:hover {
  color: #5c655a;
}
.accordion-1 a {
  color: #fff;
  text-decoration: underline !important;
}
.btn {
  background-color: transparent;
  border: 1px solid #5c655a;
  border-radius: 0;
  font-weight: 400;
  color: #5c655a;
  padding: 10px 30px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
}
.btn:hover {
  color: #fff !important;
  background-color: #4c5a4d !important;
}
ul {
  list-style: inside;
  color: #535c50;
  font-size: 15px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}
#menu-main-navigation .active,
#menu-german-navigation .active {
  font-weight: 700;
}
#component-1 {
  border-top: 24px solid #babeb9;
}
.gm-style-iw {
  font-family: 'Barlow Semi Condensed', sans-serif;
  border-radius: 0px !important;
  color: #fff;
  background-color: #93998f !important;
}
.gm-style-iw .gm-style-iw-d {
  overflow: unset !important;
  padding-right: 22px;
}
.gm-style-iw .gm-style-iw-d p {
  text-transform: uppercase;
  font-size: 18px !important;
}
.gm-style-iw .gm-ui-hover-effect img {
  filter: brightness(0) invert(1);
}
.gm-style-iw-t::after {
  height: 0px !important;
}
/* -------------------------------------------------------------------------------
    Custom Helpers
   ------------------------------------------------------------------------------- */
.bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-light {
  background-color: #e5e8e6 !important;
}
.bg-dark-grey {
  background-color: #646c61;
}
.shadow {
  box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.1);
}
.overlay {
  display: none;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
}
.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
}
.image-frame {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  padding: 5px;
}
.center-absolute-x {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.center-absolute-y {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-absolute-xy {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-block-x {
  margin-left: auto !important;
  margin-right: auto !important;
}
.center-flex-y {
/* add class to parent of centred content */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center-flex-xy {
/* add class to parent of centred content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-de {
  font-size: 60%;
  font-weight: 200;
}
.btn-primary:hover {
  border-color: #4c5a4d !important;
}
.page-id-178 ul,
.page-id-90 ul {
  padding-left: 0px;
}
.page-id-178 .immigration2 {
  min-height: 420px;
}
.immigration2 {
  min-height: 390px;
}
.immigration2 .btn {
  transform: translateX(-50%);
  position: absolute;
  bottom: 50px;
}
.page-id-90 #component-1,
.page-id-178 #component-1,
.page-id-94 #component-1,
.page-id-188 #component-1 {
  padding-bottom: 0px;
}
/* -------------------------------------------------------------------------------
    Components
   ------------------------------------------------------------------------------- */
.page {
  min-height: 100vh;
}
.component {
  position: relative;
}
.component,
.spaced {
  padding-top: 3em;
  padding-bottom: 3em;
}
.component-carousel,
.component-header {
  padding-top: 0;
  paddign-bottom: 0;
}
.component-header .header-text {
  z-index: 2;
  transform: translateY(39%);
}
.component-slider .client-title {
  text-transform: uppercase;
}
.component-slider .quote p:first-child::before {
  content: '"';
}
.component-slider .quote p:last-child::after {
  content: '"';
}
.component-slider .carousel-control-next-icon {
  transform: rotate(180deg);
  margin-right: -140px;
}
.component-slider .carousel-control-prev-icon {
  margin-left: -140px;
}
.component-slider .slick-arrow span {
  background-image: url("/content/themes/ai/dist/img/arrow.png") !important;
  width: 27px;
  height: 51px;
}
.component-form-contact {
  background-image: url("/content/themes/ai/dist/img/bg-birds.png");
  background-repeat: no-repeat;
  background-position: left top;
  padding-bottom: 0px;
  margin-bottom: -14px;
}
.component-form-contact .btn {
  margin-bottom: 0px;
}
/* Birds */
.page-template-permits #component-2 {
  background-image: url("/content/themes/ai/dist/img/bg-birds.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 200px;
}
.page-id-90 #component-2 {
  background-image: url("/content/themes/ai/dist/img/bg-birds.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto 200px;
}
.page-id-90 h2,
.page-template-permits h2,
.page-id-88 h2,
.page-id-176 h2,
.page-id-178 h2 {
  font-size: 52px !important;
}
.page-template-permits #component-2,
.page-id-88 #component-2,
.page-id-176 #component-2 {
  padding-top: 0;
}
/* Gallery */
.gallery {
  justify-content: space-between;
}
.banner-img {
  background-size: cover;
  width: 19%;
  height: 150px;
}
.banner-img:last-child {
  margin-right: 0px;
}
/* Home */
.home #component-0 .container-fluid {
  padding: 0px;
}
.home #component-0 header {
  background-color: rgba(76,90,77,0.7);
}
.page-id-174 #component-0 .container-fluid {
  padding: 0px;
}
.page-id-174 #component-0 header {
  background-color: rgba(76,90,77,0.7);
}
/* Home Services */
.home #component-1 h3,
.page-id-174 h3 {
  text-transform: none !important;
}
.home #component-4 p {
  letter-spacing: 0px;
}
.home #component-4 .btn {
  transform: translateX(-50%);
  position: absolute;
  bottom: 50px;
  background-color: #7f8a82;
  color: #fff;
}
.home #component-4 .col-lg-6 {
  min-height: 480px;
}
.home #component-3 {
  background-image: url("/content/themes/ai/dist/img/bg-birds.png");
  background-repeat: no-repeat;
  background-position: right top;
  padding-bottom: 0px;
  margin-bottom: -14px;
}
#component-4 .btn {
  margin-bottom: 0px !important;
}
/* Services */
.page-id-90 .btn {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
/* About page */
.page-id-134 #component-2,
.page-id-134 #component-3 {
  background-color: #e5e8e6 !important;
}
/* Visa and Permit Types page */
.my-tabs {
  margin-top: -60px;
}
.acc-section a {
  text-decoration: none;
}
.acc-section .visa-nav {
  margin-bottom: 40px;
}
.acc-section .visa-nav li {
  width: 33.33%;
}
.acc-section .visa-nav .nav-item {
  background-color: #f6f7f6;
}
.acc-section .visa-nav .nav-item a {
  text-transform: uppercase;
  font-weight: 200;
  color: #fff;
  background-color: #646c61;
  font-size: 30px;
  border-radius: unset;
  text-decoration: none;
}
.acc-section .visa-nav .nav-item a:hover {
  color: #fff;
  background-color: #a2a7a0;
  transition: 0.3s;
}
.acc-section .visa-nav .active {
  background-color: #a2a7a0 !important;
}
.acc-section .fa-plus,
.acc-section .fa-minus {
  font-size: 10px;
  margin-right: 15px;
  transform: translateY(-2px);
}
.acc-section .accordion-link {
  background-color: #e5e8e6;
  margin-bottom: 2px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  color: #4c5a4d;
  padding: 15px;
  margin-bottom: 24px;
}
.acc-section .accordion-link2 {
  display: block;
  padding-left: 0px !important;
  color: #4c5a4d;
  padding: 15px;
}
.acc-section .accordion-link:hover {
  color: #708571;
}
.acc-section .accordion-1 .accordion-inner {
  padding: 20px 50px;
  background-color: #b2b9b4;
  color: #fff;
  margin-bottom: 30px;
}
.acc-section .accordion-1 .accordion-inner p:last-child {
  margin-bottom: 0;
}
.acc-section .accordion-2 .accordion-inner {
  padding-left: 30px;
  margin-bottom: 30px;
}
.acc-section .accordion-2 .accordion-inner a {
  text-decoration: underline;
}
.acc-section .accordion-2 .accordion-inner p:last-child {
  margin-bottom: 0;
}
.acc-section .clicked-1 {
  background-color: #fff;
}
/* Carousel & Slider */
.slick-slide {
  padding: 0 1em;
  height: 20vw;
}
.slick-list {
  margin: 0 -1em;
}
.carousel-item,
.component-header {
  height: 50vw;
  overflow: hidden;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 0.4;
  transition: 0.3s ease opacity;
  z-index: 2;
}
.carousel-control-next.slick-disabled,
.carousel-control-prev.slick-disabled,
.carousel-control-next.disabled,
.carousel-control-prev.disabled {
  opacity: 0.2 !important;
  cursor: default;
}
.carousel-control-next:focus,
.carousel-control-prev:focus {
  opacity: 0.6;
}
.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 1;
}
.carousel-control-next .carousel-control-next-icon,
.carousel-control-prev .carousel-control-next-icon,
.carousel-control-next .carousel-control-prev-icon,
.carousel-control-prev .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
}
.carousel-indicators li {
  width: 30px;
  height: 3px;
  opacity: 1;
  transition: 0.3s ease all;
  cursor: pointer;
}
.carousel-indicators li.active,
.carousel-indicators li.slick-active {
  background: #fff;
}
.carousel-indicators li:hover {
  background: rgba(255,255,255,0.8);
}
.slider-mode-text .slick-slide {
  padding: 1em 4em;
  text-align: center;
}
.slider-mode-text .carousel-indicators {
  bottom: -40px;
}
@media only screen and (max-width: 768px) {
  .slider-mode-text .carousel-control-prev {
    left: 0%;
  }
  .slider-mode-text .carousel-control-next {
    right: 0%;
  }
}
/* Cards / Portfolio */
.card .card-body .card-thumbnail {
  max-height: 140px;
  overflow: hidden;
}
.card .card-body .card-text {
  height: 100px;
  overflow: hidden;
}
.page-id-5 .component-header,
.page-id-174 .component-header {
  height: 100vh;
}
.component-header {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  height: 45vw;
  margin-top: -117px;
}
.component-header.bg-image:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}
.component-header .header-text {
  position: relative;
  z-index: 111;
}
.component-header .header-text h1 {
  font-size: 70px;
  font-weight: 100;
  z-index: 111;
  letter-spacing: 3px;
}
.component-header .header-text h2 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: none;
}
.component-text-image h2,
.component-image-text h2 {
  font-size: 72px;
  font-weight: 200;
  text-align: center;
  color: #535c50;
  margin-bottom: 0px;
}
.component-text-image h3,
.component-image-text h3 {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: #8a938c;
  margin-top: 30px;
}
.component-text-image p,
.component-image-text p {
  text-align: center;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 0;
  color: #535c50;
}
.component-text-image .bg-image,
.component-image-text .bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.component-text-image .btn,
.component-image-text .btn {
  margin-top: 60px;
  margin-bottom: 40px;
}
.component-centered-text h2,
.component-centered-text-with-cols h2,
.component-centered-text h1,
.component-centered-text-with-cols h1 {
  color: #535c50;
  font-size: 72px;
  font-weight: 100;
  margin-bottom: 30px;
}
.component-centered-text .subline,
.component-centered-text-with-cols .subline {
  font-size: 18px;
  color: #7f8a82;
  margin-bottom: 35px;
}
.component-centered-text p,
.component-centered-text-with-cols p {
  font-size: 15px;
  color: #535c50;
}
.component-centered-text .btn,
.component-centered-text-with-cols .btn {
  border-radius: 0;
  margin-top: 30px;
  margin-bottom: 40px;
}
.component-centered-text .bg-light,
.component-centered-text-with-cols .bg-light {
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}
.component-centered-text .bg-light h2,
.component-centered-text-with-cols .bg-light h2 {
  font-size: 36px;
}
.component-centered-text .bg-light p,
.component-centered-text-with-cols .bg-light p {
  font-size: 15px;
  line-height: 30px;
}
.component-centered-text .bg-light p b,
.component-centered-text-with-cols .bg-light p b {
  font-size: 18px;
}
.component-slider h2 {
  font-size: 72px;
  font-weight: 100;
  text-align: center;
}
.component-slider section .slick-slide p {
  font-size: 24px;
  font-weight: 400;
}
.component-slider section .slick-slide p:last-child {
  font-size: 20px;
  font-weight: 600;
}
.component-slider section .carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 25px;
}
.component-form-contact {
  margin-bottom: 70px;
}
.component-form-contact a[href^="mailto:"] {
  font-family: sans-serif;
  color: #f00;
  font-size: 11px;
}
.component-form-contact h2 {
  font-size: 72px;
  color: #535c50;
  font-weight: 100;
  text-align: center;
  margin-bottom: 40px;
}
.component-form-contact p {
  font-size: 15px;
  text-align: center;
  color: #535c50;
  margin-bottom: 70px;
}
.component-form-contact input,
.component-form-contact select {
  height: 40px;
  background-color: #e5e8e6;
  color: #535c50;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 15px;
  border-radius: unset !important;
}
.component-form-contact input::placeholder,
.component-form-contact select::placeholder {
  color: #535c50;
  font-size: 15px;
  font-weight: 400;
}
.component-form-contact select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}
.component-form-contact .select-arrow {
  float: right;
  margin-right: 10px;
  margin-top: -38px;
}
.component-form-contact textarea {
  height: 110px;
  background-color: #e5e8e6;
  border-radius: 0;
  color: #535c50;
  font-size: 15px;
  font-weight: 400;
}
.component-form-contact textarea::placeholder {
  color: #535c50;
  font-size: 15px;
  font-weight: 400;
}
.component-form-contact button {
  background-color: #7f8a82;
  border: none;
  border-radius: 0;
  font-weight: 400 !important;
  color: #fff;
  padding: 5px 50px;
  margin-left: auto;
}
.component-form-contact button:hover {
  background-color: #fff;
  color: #7f8a82;
  border-color: #7f8a82;
}
.component-google-map {
  padding: 0px;
}
.component-google-map .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}
/* OUR SERVICES */
.immigration {
  background-color: #e5e8e6;
  color: #535c50;
}
.immigration h3 {
  margin-bottom: 50px;
}
.row .immigration:first-child,
.row .immigration2:nth-child(3) {
  border-right: 30px solid #fff;
}
.immigration2 {
  background-color: #d1d5d2;
}
.immigration2 ul {
  list-style: none;
}
.immigration2 .btn {
  background-color: #7f8a82;
  color: #fff;
  border-color: #7f8a82;
}
/* About us */
.btn-dark {
  background-color: #7f8a82;
  color: #fff;
  border: 1px solid #7f8a82;
}
/* Team */
.team-section {
  margin-top: -70px;
}
.team-section .team-member {
  position: relative;
}
.team-section .overlay {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(102,111,100,0.82);
}
.team-section .team-member:hover .overlay {
  opacity: 1;
}
.team-section .text {
  color: #fff;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}
.team-section .text h3 {
  padding-bottom: 20px;
}
.team-section .text p {
  color: #fff;
}
.team-section .text .social {
  padding-top: 20px;
}
.team-section .de p {
  font-size: 14px;
}
.team-section .list-unstyled a {
  border: 1px solid #fff;
  width: 31px;
  height: 31px;
  display: inline-block;
  position: relative;
}
.team-section .list-unstyled a i {
  margin-top: 7px;
  margin-left: 1px;
}
.team-section .fab,
.team-section .far {
  color: #fff;
  font-size: 16px;
}
.team-section .fab:hover,
.team-section .far:hover {
  opacity: 0.8;
}
.mobile {
  display: none;
}
/*	------------------------------------------------------------------------------
	FOOTER
	------------------------------------------------------------------------------ */
footer#footer {
  padding-bottom: 0;
}
footer#footer h6 {
  font-size: 24px;
  color: #a9b2a4;
  font-weight: 300;
  margin-left: 70px;
  margin-bottom: 28px;
}
footer#footer ul {
  text-transform: uppercase;
  margin-left: 70px !important;
}
footer#footer ul li {
  font-size: 15px;
  color: #fff;
}
footer#footer ul li a {
  color: #fff;
  transition: all 0.3s;
  text-decoration: none;
  padding: 0px;
}
footer#footer ul li a i.fab {
  margin-right: 0;
}
footer#footer ul li a:hover {
  opacity: 0.4;
  cursor: pointer;
}
footer#footer .legal {
  height: 60px;
  align-items: center;
}
footer#footer .legal div {
  color: #a9b2a4;
}
footer#footer .column-1,
footer#footer .column-2 {
  border-right: 1px solid #a9b2a4;
}
footer#footer .column-1 {
  border-left: 1px solid #a9b2a4;
}
footer#footer .column-3 {
  border-right: 1px solid #a9b2a4;
}
footer#footer .column-3 a {
  text-transform: lowercase;
}
footer#footer .bg-dark-grey {
  padding-bottom: 50px;
  padding-top: 50px;
}
/* -------------------------------------------------------------------------------
    Style Bootstrap Classes
   ------------------------------------------------------------------------------- */
@media only screen and (min-width: 992px) {
  #nav {
    height: 117px;
  }
  .page-id-5 .anim,
  .page-id-5 .anims {
    opacity: 0;
  }
}
.subnav {
  top: 117px;
  z-index: 1020;
}
.has-subnav {
  margin-top: 117px;
}
.navbar-nav > li:first-child > a.nav-link {
  padding-left: 0px;
}
.navbar {
  backface-visibility: hidden;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  font-weight: 500;
}
.navbar .dropdown-menu li {
  width: 100%;
  margin-left: 0 !important;
}
/* -------------------------------------------------------------------------------
    Footer
   ------------------------------------------------------------------------------- */
#footer {
  margin-top: 0;
  min-height: 250px;
  font-size: 90%;
}
#footer li {
  margin-bottom: 5px;
}
#footer .fab {
  color: #fff;
  margin-right: 5px;
}
#footer .social {
  display: flex;
}
#footer .social li {
  width: 36px;
  height: 34px;
  background-color: #a9b2a4;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* -------------------------------------------------------------------------------
    Forms
   ------------------------------------------------------------------------------- */
label {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  font-weight: 700;
}
::placeholder {
  text-transform: uppercase;
}
.custom-control label {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}
select {
  border-radius: unset !important;
}
input,
select,
textarea {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  font-weight: 500;
}
.result {
  margin-top: 20px;
  margin-bottom: 40px;
  display: none;
  color: #535c50;
}
.result :first-child {
  margin-top: 0;
  padding-top: 0;
}
.parsley-errors-list {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #f00;
  display: block;
  overflow: hidden;
  font-size: 12px;
  margin-top: -16px;
  position: absolute;
}
.msg-input .parsley-custom-error-message {
  margin-top: 15px;
}
.parsley-custom-error-message {
  margin-top: 0px;
}
.parsley-error,
:focus.parsley-error {
  border-color: #f00;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control:-moz-placeholder {
  color: #999;
}
.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control.placeholder {
  color: #999;
}
/* -------------------------------------------------------------------------------
    Navigation
   ------------------------------------------------------------------------------- */
#nav {
  height: 120px;
  background-color: rgba(255,255,255,0.9);
}
#nav .navbar-brand img {
  width: 292px;
  margin-top: -11px;
}
#nav .top-items {
  transform: translateX(14px);
}
#nav .top-items .active {
  background-color: #758a6f !important;
  color: #fff;
  border: 1px solid #a5aaa3;
}
#nav .top-items a {
  text-decoration: none;
  padding: 6px 7px;
  border-style: solid;
  border-width: 1px;
}
#nav .top-items a:first-child {
  margin-right: 10px;
}
#nav .top-items .container {
  height: 117px;
}
#nav #navbar .navbar-nav {
  transform: translateX(42px);
  margin-top: 105px;
  height: 140px;
}
#nav #navbar .navbar-nav .active .nav-link {
  color: #535c50;
}
#nav #navbar .navbar-nav .nav-link {
  font-size: 14px;
  color: #62695f;
  padding: 15px 25px;
  text-transform: uppercase;
  transition: all 0.3s;
  text-decoration: none;
}
#nav #navbar .navbar-nav .nav-link:hover {
  color: #535c50;
}
#nav #navbar .menu-item:last-child {
  margin-right: 0px;
}
#nav #navbar .lag-btns {
  position: absolute;
  right: 30px;
  top: 14px;
}
#nav .top-items {
  top: 14px;
}
#nav .top-items a:first-child {
  padding-right: 3px;
}
/* -------------------------------------------------------------------------------
    Media Queries
   ------------------------------------------------------------------------------- */
@media only screen and (max-width: 768px) {
  .home #component-4 .col-md-6 {
    min-height: none !important;
  }
  .home #component-4 .btn {
    position: relative;
    transform: translateX(0%);
    margin-top: 50px;
  }
  .component-form-contact {
    background-image: none;
  }
  .page-template-permits #component-1,
  .page-id-90 #component-1 {
    background-image: none;
  }
  .page-id-90 #component-1 {
    background-image: none;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .team-member {
    margin-top: 70px;
  }
  .team-member p {
    font-size: 15px !important;
    color: #535c4f;
  }
  .team-member .social a {
    border: 1px solid #535c4f;
  }
  .team-member .fab,
  .team-member .far {
    color: #535c4f;
  }
  .team-member h3 {
    color: #535c4f;
  }
  .team-member img {
    margin-bottom: 20px;
  }
  .page-id-5 .component-header,
  .page-id-174 .component-header {
    height: 50vh;
  }
  .top-items a:first-child {
    padding-right: 8px !important;
  }
}
@media only screen and (min-width: 991px) {
  #nav #navbar {
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 992px) {
  #nav {
    padding-top: 0;
    height: 96px;
  }
  #nav .container {
    position: unset;
  }
  #nav .container >#navbar {
    position: absolute;
    width: 100%;
    top: 105px;
  }
  #nav #navbar {
    left: 0;
  }
  #nav .top-items {
    display: block !important;
    position: absolute;
    top: 40px;
    right: 16px;
  }
  #nav .top-items a:first-child {
    margin-bottom: 10px;
  }
  #nav .top-items a {
    display: block;
    color: #fff;
    font-size: 12px;
  }
  #nav .top-items a:nth-child(2) {
    width: 32px !important;
  }
  #nav .top-items .active {
    color: #7f8a82;
    background-color: #fff !important;
  }
  #nav .navbar-brand img {
    width: 250px !important;
    margin-top: 0px;
  }
  #nav #menu-main-navigation,
  #nav #menu-german-navigation {
    background-color: #7f8a82;
    position: relative;
    top: -22px;
  }
  #nav #menu-main-navigation li,
  #nav #menu-german-navigation li {
    padding-left: 15px;
    line-height: 10px;
  }
  #nav #menu-main-navigation li a,
  #nav #menu-german-navigation li a {
    color: #fff !important;
    padding: 5px 0px !important;
  }
  #nav .top-items {
    z-index: 10;
  }
  .page-id-5 .anim,
  .page-id-5 .anims {
    opacity: 1 !important;
    transition: opacity 0.5s ease;
  }
  .navbar-nav {
    transform: translateX(0) !important;
  }
  footer#footer .column-3 {
    border-right: none;
  }
  footer#footer .column-1 {
    border-left: none;
  }
  footer#footer .column-2 {
    border-right: none;
  }
  .home #component-3 {
    background-image: none;
  }
  .component-centered-text-with-cols .bg-light {
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  .row .immigration:first-child,
  .row .immigration2:nth-child(3) {
    border-right: none;
  }
  .component-form-contact input,
  .component-form-contact select,
  .component-form-contact textarea {
    font-size: 13px;
  }
  .component-form-contact input::placeholder,
  .component-form-contact select::placeholder,
  .component-form-contact textarea::placeholder {
    font-size: 13px;
  }
  .acc-section .accordion-link {
    text-align: center;
  }
  .acc-section .accordion-link span {
    display: inline-block;
    width: 85%;
  }
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 40px !important;
  }
  .component-header h2 {
    font-size: 16px !important;
  }
  .component-text-image,
  .component-image-text {
    padding-bottom: 0;
  }
  .component-text-image .bg-image,
  .component-image-text .bg-image {
    min-height: 250px;
    margin-top: 20px;
  }
  #footer .column-2 {
    border-right: none !important;
  }
  .component-centered-text .bg-light {
    margin-bottom: 15px;
  }
  .navbar-brand img {
    width: 200px !important;
  }
  #menu-main-navigation,
  #menu-german-navigation {
    top: 12px !important;
    margin-top: 0 !important;
    padding-top: 25px;
    padding-left: 38px;
  }
  #navbar {
    top: 82px !important;
  }
  .navbar-toggler {
    position: absolute;
    right: -1px;
    top: 49px;
  }
  .navbar-toggler img {
    width: 31px;
  }
  .header-text {
    display: none;
  }
  .component-header .header-text h1 {
    font-size: 30px;
  }
  .component-header .header-text h2 {
    font-size: 16px;
  }
  .component-form-contact,
  .home #component-3 {
    background-size: 90px;
  }
  .carousel-control-next-icon {
    margin-right: 0 !important;
  }
  .carousel-control-prev-icon {
    margin-left: 0 !important;
  }
  .slick-list {
    height: 60vh;
  }
  .component-slider {
    height: 100vh !important;
  }
  .component-slider .carousel-control-next-icon {
    margin-left: 50px;
    width: 15px;
    height: 28px;
  }
  .component-slider .carousel-control-prev-icon {
    margin-right: 50px;
  }
  .mobile-lang {
    background-color: #7f8a82;
    margin-top: 10px;
    padding: 15px 0;
    justify-content: center;
  }
  .mobile-lang .top-items {
    display: contents !important;
  }
  #nav >.container >#navbar {
    top: 60px;
  }
  .component-header {
    height: 60vh !important;
  }
  footer#footer h6 {
    margin-bottom: 10px;
  }
  footer#footer .column-1 {
    border-right: none;
  }
  .quote p {
    font-size: 18px !important;
  }
  .page-id-5 #component-5,
  .page-id-174 #component-5 {
    background-image: url("/content/themes/ai/dist/img/bg-mobile-refs.jpg") !important;
    background-position-x: -146px;
  }
  .acc-section .accordion-link {
    display: flex;
    align-items: center;
  }
  .acc-section .accordion-link .fa-plus,
  .acc-section .accordion-link .fa-minus {
    transform: none !important;
  }
  .component-gallery .banner-img {
    width: 33%;
    background-position: right;
  }
  .component-gallery .banner-img:first-child,
  .component-gallery .banner-img:last-child {
    display: none;
  }
  .component-centered-text.bg-white {
    padding-bottom: 15px;
  }
  .component-centered-text.bg-white p {
    margin-bottom: 10px;
  }
  .component-centered-text.bg-white p a {
    margin-bottom: 5px;
  }
  .component-header {
    background-position-x: -145px;
    background-position-y: 96px;
  }
  .acc-section .visa-nav .nav-item a {
    padding: 5px;
  }
}
@media only screen and (max-width: 576px) {
  .component-form-contact,
  .home #component-3 {
    background-size: 50px;
  }
  .slider-mode-text .slick-slide {
    padding: 0 20px;
  }
  .slick-slider .slick-list {
    padding: 0 20px !important;
  }
  .btn {
    padding: 10px 20px;
  }
  .acc-section .visa-nav .nav-item a {
    font-size: 16px;
  }
  .component-header {
    height: 50vh;
  }
  .page-id-90 .immigration2 {
    min-height: 470px;
  }
  .page-id-178 .immigration2 {
    min-height: 560px;
  }
  #visum-fur-selbstandige-unternehmer i,
  #visum-auf-basis-eines-abkommens i,
  #visum-fur-schuler-und-studenten i,
  #ehegatten-und-lebenspartner-visa i,
  #spousal-and-life-partner-visa i,
  #visum-fur-medizinische-behandlung i,
  #befristetes-aufenthalts-visum i {
    transform: translateY(-14px);
  }
  #projektbezogene-visa-corporate-visa-fur-unternehmen i,
  #visum-fur-gemeinnutzige-wohltatige-oder-freiwillige-arbeit i {
    transform: translateY(-25px);
  }
}
@media only screen and (max-width: 576px) {
  .page-id-90 .order-2 {
    min-height: 440px;
  }
  .page-id-90 .order-3 {
    min-height: 530px;
  }
  .page-id-178 .order-2 {
    min-height: 630px;
  }
  .page-id-178 .order-3 {
    min-height: 600px;
  }
  .immigration h3 {
    font-size: 1.75rem;
  }
  #component-4 h2 {
    font-size: 1.75rem !important;
  }
  .visa-nav .nav-link {
    font-size: 13px !important;
  }
  .quote p {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 320px) {
  .quote p {
    font-size: 12px !important;
  }
}
